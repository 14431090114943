exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-games-index-js": () => import("./../../../src/pages/games/index.js" /* webpackChunkName: "component---src-pages-games-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-game-page-js": () => import("./../../../src/templates/game-page.js" /* webpackChunkName: "component---src-templates-game-page-js" */),
  "component---src-templates-job-post-js": () => import("./../../../src/templates/job-post.js" /* webpackChunkName: "component---src-templates-job-post-js" */)
}

